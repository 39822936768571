/** @jsx jsx */
import { jsx, Text } from "theme-ui";

import DefaultLayout from "../../layouts/dlw-mobile";

import MenuItems from "../../components/MenuItems";
import ashramOfficeMenuItems from "../../components/MenuItems/ashramOfficeMenuItems";

const Index = (/* { data } */) => {
  return (
    <DefaultLayout>
      <div sx={{ mt: 4 }}>
        <div sx={{ pt: 2, textAlign: "center" }}>
          <Text variant="header">Ashram Office</Text>
        </div>
        <MenuItems items={ashramOfficeMenuItems} />
      </div>
    </DefaultLayout>
  );
};

export default Index;
