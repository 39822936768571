/** @jsx jsx */
import HFNMeditate from "../../assets/freedom.jpg";

const daajiMenuItems = [
  {
    label: "FRRO - Overseas Visitors",
    subLabel: "Kanha Overseas Visitors >>",
    to: "/ashram-office/overseas-visitors",
    icon: HFNMeditate,
    key: "22",
  },
  {
    label: "Volunteering Requests",
    subLabel: "Kanha Volunteering >>",
    to: "/ashram-office/volunteer-requests",
    icon: HFNMeditate,
    key: "24",
  },
];

export default daajiMenuItems;
